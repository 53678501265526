'use client'

import dynamic from 'next/dynamic'
import { usePathname } from 'next/navigation'
import Link from 'next/link'
import { AppBar, Toolbar, Grid, Container } from '@mui/material'

// Types
import type { AppBarProps } from '@/types/components/organisms/app-bar'

// Helpers
import { useTranslation } from '@/helpers/i18n/client'

// Components
const HideOnScroll = dynamic(() => import('@/components/theme/hide-on-scroll'))
const MenuMolecule = dynamic(() => import('@/components/molecules/menu/desktop'))
const LogoAtom = dynamic(() => import('@/components/atoms/logo'))
const ModeAtom = dynamic(() => import('@/components/atoms/mode'))
const GeneralButtonAtom = dynamic(() => import('@/components/atoms/buttons/general'))

const AppBarOrganism = (props: AppBarProps) => {
	// Props
	const { menus } = props

	// Variables
	const { t } = useTranslation()
	const pathname = usePathname()

	return (
		<HideOnScroll onlyDesktop={pathname !== '/'}>
			<AppBar
				elevation={0}
				sx={{
					display: { xs: 'none', md: 'block' },
					position: pathname === '/' ? 'relative' : 'fixed',
					pt: 4,
					bgcolor: pathname === '/' ? 'transparent' : 'background.default',
					'& .MuiToolbar-root': { p: 0 }
				}}
			>
				<Toolbar>
					<Grid container flexDirection="column">
						<Container maxWidth="xl">
							<Grid container direction="row" justifyContent="space-between" alignItems="center">
								<Grid item ml={3} mr={4.5}>
									<LogoAtom />
								</Grid>

								<MenuMolecule items={menus} />

								<Grid item>
									<Grid container direction="row" justifyContent="flex-start" alignItems="center">
										<Grid item>
											<Link href="#">
												<GeneralButtonAtom>{t('form:button.downloadApp')}</GeneralButtonAtom>
											</Link>
										</Grid>

										<Grid item>
											<ModeAtom />
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Container>
					</Grid>
				</Toolbar>
			</AppBar>
		</HideOnScroll>
	)
}

export default AppBarOrganism
