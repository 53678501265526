'use client'

import { useState } from 'react'
import dynamic from 'next/dynamic'
import { usePathname } from 'next/navigation'
import { useColorScheme } from '@mui/material/styles'
import { useMediaQuery, AppBar, Toolbar, Grid, Container, IconButton } from '@mui/material'
import { Menu as MenuIcon } from '@mui/icons-material'

// Types
import type { AppBarProps } from '@/types/components/organisms/app-bar'

// Components
const DrawerOrganism = dynamic(() => import('@/components/organisms/drawer'))
const LogoAtom = dynamic(() => import('@/components/atoms/logo'))

const AppBarOrganism = (props: AppBarProps) => {
	// Props
	const { menus } = props

	// Variables
	const pathname = usePathname()
	const [open, setOpen] = useState<boolean>(false)

	// Mode
	const { mode } = useColorScheme()
	const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
	const themeMode = mode == 'system' ? (prefersDarkMode ? 'dark' : 'light') : mode

	return (
		<AppBar
			elevation={0}
			sx={{
				display: { xs: 'block', md: 'none' },
				bgcolor: pathname === '/' ? 'transparent' : 'background.paper',
				'& .MuiToolbar-root': { p: 0 }
			}}
		>
			<Toolbar>
				<Container maxWidth="xs">
					<Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ width: 1 }}>
						<Grid
							item
							xs={6}
							height={40}
							sx={{
								'& img': {
									width: 'auto',
									height: 40
								}
							}}
						>
							<LogoAtom />
						</Grid>

						<Grid item>
							<IconButton
								color={pathname === '/' ? (themeMode === 'light' ? 'primary' : 'inherit') : 'secondary'}
								aria-label="toggle menu visibility"
								onClick={() => setOpen(!open)}
							>
								<MenuIcon />
							</IconButton>
						</Grid>
					</Grid>
				</Container>

				<DrawerOrganism open={open} setOpen={setOpen} items={menus} />
			</Toolbar>
		</AppBar>
	)
}

export default AppBarOrganism
